/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveMeta } from "@dataResolvers";
import { About as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query {
    craft {
      entry(section: "about") {
        ... on Craft_about_about_Entry {
          # meta
          url
          slug
          title
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          metaDescription
          metaTitle
          uid
          # hero
          heroHeading
          heroSubheading
          heroBackgroundImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          scrimOpacity
          # history
          history {
            ... on Craft_history_historyBlock_BlockType {
              historyTitle
              historyText
              historyImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          # sri baba
          visitVirtuallyHeading
          fullWidthText
          linkField {
            url
            text
          }
          visitVirtuallyImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          scrimOpacity0
          eventContent
          visitVirtuallyDescriptor
          eventImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          visitVirtuallyHeading2
          visitVirtuallySubheading2
          # image gallery
          imageGallery {
            ... on Craft_imageGallery_image_BlockType {
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          # related activities
          supportBlockHeading
          subheading
          relatedActivities {
            ... on Craft_relatedActivities_activityBlock_BlockType {
              heading
              image {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              blurb
              linkObj {
                text
                url
              }
            }
          }
        }
      }
    }
  }
`;

const previewQuery = `
query {
  craft {
    entry(section: "about") {
      ... on Craft_about_about_Entry {
        uid
        # hero
        heroHeading
        heroSubheading
        heroBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        scrimOpacity
        # history
        history {
          ... on Craft_history_historyBlock_BlockType {
            historyTitle
            historyText
            historyImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        # sri baba
        visitVirtuallyHeading
        fullWidthText
        linkField {
          url
          text
        }
        visitVirtuallyImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        scrimOpacity0
        eventContent
        visitVirtuallyDescriptor
        eventImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        visitVirtuallyHeading2
        visitVirtuallySubheading2
        # image gallery
        imageGallery {
          ... on Craft_imageGallery_image_BlockType {
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        # related activities
        supportBlockHeading
        subheading
        relatedActivities {
          ... on Craft_relatedActivities_activityBlock_BlockType {
            heading
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            blurb
            linkObj {
              text
              url
            }
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  const {
    // meta
    title,
    metaTitle,
    metaImage,
    metaDescription,
    slug,
    url,
    // other
    heroHeading,
    heroButtonLink,
    heroSubheading,
    heroBackgroundImage,
    imageGallery,
    history,
    supportBlockHeading,
    subheading,
    relatedActivities,
    visitVirtuallyHeading,
    fullWidthText,
    linkField,
    visitVirtuallyImage,
    eventContent,
    visitVirtuallyDescriptor,
    eventImage,
    visitVirtuallyHeading2,
    visitVirtuallySubheading2,
    scrimOpacity,
    scrimOpacity0,
  } = craft.entry;

  return {
    meta: resolveMeta({
      title,
      metaTitle,
      metaImage,
      metaDescription,
      slug,
      url,
    }),
    hero: {
      heading: heroHeading,
      content: heroSubheading,
      button: heroButtonLink,
      image: resolveImage(heroBackgroundImage),
      scrimOpacity,
    },
    history: {
      heading: history?.[0]?.historyTitle,
      content: history?.[0]?.historyText,
    },
    carousel: history?.[0]?.historyImage.map(img => {
      return { image: resolveImage(img) };
    }),
    about: {
      heading: visitVirtuallyHeading,
      text: fullWidthText,
      button: linkField,
      content: {
        image: resolveImage(visitVirtuallyImage),
        heading: eventContent,
        scrimOpacity: scrimOpacity0,
        text: visitVirtuallyDescriptor,
        innerImage: resolveImage(eventImage),
        subhead: visitVirtuallyHeading2,
        innerText: visitVirtuallySubheading2,
      },
    },
    grid: imageGallery.map(i => resolveImage(i.image)),
    related: {
      heading: supportBlockHeading,
      content: subheading,
      activities: relatedActivities.map(a => {
        return {
          name: a.heading,
          image: resolveImage(a.image),
          description: a.blurb,
          link: a.linkObj,
        };
      }),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
